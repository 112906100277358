// ================================================================================
// script.js
// 案件用のスクリプトファイル
// ================================================================================

// DOM Read for Vanilla
// --------------------------------------------------------------- //

document.addEventListener('DOMContentLoaded', () => {

	// breakpoint
	const breakpoint = window.matchMedia( 'screen and (min-width: 960px)' )

	// scroll volume
	function getScrolled() {
		return ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop;
	}



	// variable
	// --------------------------------------------------------------- //

	// scroll
	let scrollTop = window.pageYOffset

	// url
	let url = location.href



	// Dropdown for mobile
	// --------------------------------------------------------------- //

	const dropDownButton = document.getElementById('js-mobile-menu')
	const menuOpenTarget = document.querySelector('body')
	//const dropDownMenu   = document.getElementById('js-site-menu')

	if ( dropDownButton ) {
		dropDownButton.addEventListener('click', function() {
			dropDownButton.classList.toggle('is-close')
			menuOpenTarget.classList.toggle('is-menu-open')
			//dropDownMenu.classList.toggle('is-active')
		} )
	}



	// Front Page Main Swiper
	// --------------------------------------------------------------- //

	const swiperMainId = document.getElementById('front-main-swiper');

	if ( swiperMainId ) {
		const swiper = new Swiper(swiperMainId, {
			loop: true,
			autoplay: {
				delay: 4000,
				disableOnInteraction: false,
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			speed: 1500,
			breakpoints: {
				768: {
					spaceBetween: 8,
					slidesPerView: 'auto',
					// grabCursor: true,
					centeredSlides: true,
					//centerInsufficientSlides: true,
				}
			}
		});
	}



	// Front Page News Swiper
	// --------------------------------------------------------------- //

	const getPosts = 'https://collegetown.jp/wp-json/wp/v2/posts?_embed&per_page=10'

	let viewPosts = document.getElementById('front-news-posts');

	fetch(getPosts).then(
		response => {
			if ( viewPosts ) {
				return response.json();
			}
	}).then(data => {
		for (let i = 0; i < data.length; i++) {

			viewPosts.innerHTML += '<div class="swiper-slide front-news-slide">'
				+ '<article class="front-news-item">'
					+ '<p class="front-news-item__thumb">'
						+ '<a href="' + data[i].link + '" class="linkID_' + data[i].id +'" target="_blank">'
							+ '<img src="' + data[i]._embedded["wp:featuredmedia"][0].source_url + '" alt="' + data[i].title.rendered + '">'
						+ '</a>'
					+ '</p>'
					+ '<p class="front-news-item__title">'
						+ '<a href="' + data[i].link + '" class="linkID_' + data[i].id +'" target="_blank">' + data[i].title.rendered + '</a>'
					+ '</p>'
				+ '</article>'
			+ '</div>'
		}

	}).catch(err => {
		if ( viewPosts ) {
			console.log('Error: ' + err)
		}
	});


	const swiperNewsId = document.getElementById('front-news-swiper');

	if ( swiperNewsId ) {
		const swiper = new Swiper(swiperNewsId, {
			loop: false,
			spaceBetween: 8,
			slidesPerView: 'auto',
			grabCursor: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			breakpoints: {
				768: {
					spaceBetween: 16,
				}
			}
		});
	}


} );


$(function() {


});




